<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

/**
 * Admin Form component
 */
export default {
  page: {
    title: "Cadastrar Admin",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  data() {
    return {
      title: "Cadastrar Admin",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Admins",
          href: "/manage-admins",
        },
        {
          text: "Cadastrar Admin",
          active: true,
        },
      ],
      form: {
        name: "",
        lastName: "",
        email: "",
        senha: "",
        image: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      lastName: { required },
      email: { required },
      password: { required },
      image: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">
      <div class="col-xl">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Formulário de Cadastro do Curso</h4>
            <form action="#" @submit.prevent="form">
              <form-wizard
                shape="tab"
                color="#626ed4"
                nextButtonText="Próximo"
                backButtonText="Anterior"
                finishButtonText="Cadastrar"
              >
                <tab-content icon="fas fa-pencil-alt" title="Básico">
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Nome <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="form.name"
                            type="text"
                            class="form-control"
                            placeholder="Dígite o nome do admin"
                            name="name"
                            :class="{
                              'is-invalid': typesubmit && $v.form.name.$error,
                            }"
                          />
                          <div
                            v-if="typesubmit && $v.form.name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.name.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Sobrenome <span class="required">*</span></label
                        >
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col">
                              <input
                                v-model="form.lastName"
                                type="text"
                                class="form-control"
                                placeholder="Selecione o sobrenome"
                                name="lat-name"
                                :class="{
                                  'is-invalid':
                                    typesubmit && $v.form.lastName.$error,
                                }"
                              />
                              <div
                                v-if="typesubmit && $v.form.lastName.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.form.lastName.required"
                                  >Este campo é obrigatorio.</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <label class="col-md-3 form-label"
                          >Imagem do Usuário</label
                        >
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col">
                              <div class="d-flex w-100">
                                <img
                                  class="rounded-circle img-thumbnail"
                                  src="https://sistema.speednow.com.br/uploads/user_image/7c44fb064b8f8d4f856df4c91bc2a6c1.jpg"
                                  alt=""
                                  style="height: 50px; width: 50px"
                                />
                                <div class="w-100" style="margin-left: 10px">
                                  <input
                                    id="validationCustom02"
                                    type="file"
                                    class="form-control w-100"
                                    placeholder="Ex: 1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content icon="mdi mdi-lock" title="Credênciais de Login">
                  <div class="row mb-4">
                    <label class="col-md-3 form-label"
                      >E-mail <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.email"
                        type="text"
                        class="form-control"
                        placeholder="Dígite o e-mail do dmin"
                        name="email"
                        :class="{
                          'is-invalid': typesubmit && $v.form.email.$error,
                        }"
                      />
                      <div
                        v-if="typesubmit && $v.form.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.email.required"
                          >Este campo é obrigatorio.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <label class="col-md-3 form-label"
                      >Senha <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col">
                          <input
                            v-model="form.password"
                            type="password"
                            class="form-control"
                            placeholder="Digite a senha"
                            name="password"
                            :class="{
                              'is-invalid':
                                typesubmit && $v.form.password.$error,
                            }"
                          />
                          <div
                            v-if="typesubmit && $v.form.password.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.password.required"
                              >Este campo é obrigatorio.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content icon="far fa-check-circle" title="Finalizar">
                  <div class="row">
                    <div class="col-12">
                      <div class="text-center">
                        <h2 class="mt-0">
                          <i class="mdi mdi-check-all"></i>
                        </h2>
                        <h3 class="mt-0">Finalizar Cadastro!</h3>

                        <p class="w-75 mb-2 mx-auto">
                          Tudo Pronto! Agora Clique em "Cadastrar" para
                          Finalizar o Cadastro do Curso.
                        </p>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                </tab-content>
              </form-wizard>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
